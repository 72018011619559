
import React from 'react';
import {Link} from 'react-router-dom';


/*-----------------------ENGLISH------------------------*/

var english = {

    frontpage: {

        "text": "Performances for every occasion!", 
        "text2": "Shows for all ages!",
        "words": ["Performances for every occasion!", "\"Professional, interactive and entertaining virtuosity!\"", "\"Kai Kuutamo is a convincing and charismatic performer.\""],
        "words2": ["Shows for all ages!", "- Olli Lepänrinne / MagnumLive", "- Turun Sanomat Newspaper"]

    },


    header: 

    <div id="headlinkwrap">

    <nav>

        <ul>
                <li><Link to="/who">WHO</Link></li>
                <li><Link to="/services">SERVICES</Link></li>
                <li><Link to="/shows">SHOWS</Link></li>
                <li><Link to="/media">PHOTOS</Link></li>
                <li><Link to="/videos">VIDEOS</Link></li>
                <li><Link to="/calendar">CALENDAR</Link></li>
                <li><Link to="/contact">CONTACT</Link></li>
        </ul>

    </nav>

    </div>,

who:
<div>
<div className="tabletitlewrap">
<h1 className="tabletitle">Circus Artist Kai Kuutamo</h1>
 
</div>

 <div className="tableintro">

 
 <div className="tabletext">
 

<p>PERFORMANCES FOR EVERY OCCASION!</p>

<p>Kai Kuutamo is a circus artist who performs at the most different of places and events. His specialty being juggling and comedy Kuutamo works alone and together with other circus artists and musicians depending on the act or show involved. For Kai Kuutamo it is important to take circus to unexpected places and to the people where ever they might be.</p>

<p><i><font color="yellow">"Professional, interactive and entertaining virtuosity!"</font></i></p>
    <p><font color="yellow">- Olli Lepänrinne, Performace Agent, MagnumLive</font></p>

  
<p>Within the performances improvisation and comedy are his most important tools among the art of juggling. His view to his work is one of a street performer who values encountering people and the audience over fully scripted routines or technical perfection. The audience makes the performance as much as the performer. Every audience being different no two shows are ever 100% the same.</p>

<p><i><font color="yellow">"You turn good self-esteem into an art form!"</font></i></p>
    <p><font color="yellow">- Harri Lukander, Master of Ceremonies</font></p>

<p>Kai Kuutamo has studied circus and juggling in the Finnish cities of Lahti and Turku and in Kiev Ukraine. Based in Turku Finland he also performs abroad. He has performed in over 10 countries the farthest for now being Cambodia. Venues vary from festivals and theatres to kindergartens and retirement homes. Kai Kuutamo is also the owner of the Finnish online circus shop Sirkuskauppa.com.</p>

<p><i><font color="yellow">"We have been using Kai Kuutamo and his circus services in many events and occasions around Finland. We are more than pleased with him and his performances. Kai's way of operating is excellent and he can also produce very ingenious and deliverable ideas. On top of that Kai is an overall pleasant guy! We recommend him warmly to everyone."</font></i></p>

<p><font color="yellow">- Thomas Boucht, Event Producer, Suomen Kauppakeskustapahtumat</font></p>

 </div>
 </div>
 <div id="wholinks">
     <h2>Links</h2>

     <p><a href="https://tip.sirkussirkus.com" rel="noopener noreferrer" target="cv">Kai Kuutamo Tip and Donate</a></p>
     <p><a href="https://onlyfans.com/kaikuutamo" rel="noopener noreferrer" target="of">Kai Kuutamo OnlyFans</a></p>
     <p><a href="https://www.sirkussirkus.com/cv.pdf" rel="noopener noreferrer" target="cv">Resume</a></p>
     <p><a href="https://www.sirkussirkus.com/portfolio" rel="noopener noreferrer" target="portfolio">Portfolio</a></p>
     <p><a href="https://fi.wikipedia.org/wiki/Kai_Kuutamo" rel="noopener noreferrer" target="wiki">Kai Kuutamo in Finnish Wikipedia</a></p>
     <p><a href="https://kaikuutamo.sirkussirkus.com/articles" rel="noopener noreferrer">Articles and other media appearances</a></p>
     <p><a href="https://putiikki.sirkussirkus.com/" rel="noopener noreferrer" target="blank">Sirkuskauppa.com</a></p>
     <p><a href="https://www.sirkussirkus.com/multibueno/index.html" rel="noopener noreferrer" target="blank">Multi Bueno Festival</a></p>
     <p><a href="https://www.facebook.com/circuskampot" rel="noopener noreferrer" target="blank">Circus Kampot social circus project (2013-2016)</a></p>
     <p>.</p>
     </div>
     </div>,

services: 
<div>
<div className="tabletitlewrap">
 <h1 className="tabletitle">Services</h1>
</div>
<div className="tableintro">
    <div className="tabletext">
        <p>Kai Kuutamo & Sirkussirkus offers three kinds of services:</p>
        <p>- Full shows, circus acts, hosting and tailored program</p>
        <p>- Juggling workshops for all ages</p>
        <p>- Circus equipment import and resale</p>
    </div>
</div>
<div className="tableh2wrap">
   <img className="servicespic" src="pictures6/sirkusesitys.jpg" alt="Services"></img>
    <h2>Shows and program</h2>
</div>
<div className="tableintro">
    <div className="tabletext">
        
    <p>Master of ceremony, hosting, atmosphere and mood manipulation.</p> 
                <p>Fluently, funny, surprising and according to contract with or without circus acts!</p>
c
        <p>I offer ready made full shows from 15 minutes to 45 minutes. Solo shows and shows with other circus artists and musicians.I also do hosting for parties and events. Hosting can be mixed with small acts or the event can include one of the full shows. Tailor made performances for your needs are also possible. Contact for pricing and more information.</p>
    </div>
</div>
<div className="tableh3wrap">
<img className="servicespic" src="pictures6/sirkustyopaja.jpg" alt="Services"></img>
    <h3>Juggling workshops</h3>
</div>
<div className="tableintro">
    <div className="tabletext">
        <p>Juggling classes and workshops for all ages. These can be held in 1 hour long structured classe for maximum groups of 20. These work great for work groups, conferences and youth alike. Can also be organized as open workshops for festivals and bigger events where people can come and go as they please and I help everyone personally the best I can. Contact for pricing and more information.</p>
        
    </div>
</div>
<div className="tableh4wrap">
<img className="servicespic" src="pictures6/sirkusvalineet.jpg" alt="Services"></img>
    <h4>Circus equipment</h4>
</div>
<div className="tableintro">
    <div className="tabletext">
        <p>We import and retail circus equipment in Finland. For more contact us via email or visit our online shop:</p>
<p><span id="shoplink"><a href="http://putiikki.sirkussirkus.com/" target="blank">www.sirkuskauppa.com</a></span> (in Finnish).</p>
        
    </div>
</div>
</div>,
mobilemenu: 
     
<div className="licont">
<li><Link to="/who">WHO</Link></li>
<li><Link to="/services">SERVICES</Link></li>
<li><Link to="/shows">SHOWS</Link></li>
<li><Link to="/media">PHOTOS</Link></li>
<li><Link to="/videos">VIDEOS</Link></li>
<li><Link to="/calendar">CALENDAR</Link></li>
<li><Link to="/contact">CONTACT</Link></li>
</div>,

videos: 

<div>

       <div className="tabletitlewrap">

       

       </div>

       

       <div className="videos">

       <h2>Live</h2>

<p>Kai Kuutamo Show - Finlaysonin Alue, Tampere 2022</p>

<div className="icont">
<div className="icont2">
<iframe className="video" title="1" src="https://www.youtube.com/embed/g9MeGYo9_qw" allowFullScreen></iframe>
</div>
</div>

<center>
<div className="white-line3"></div>
</center>

<p>Moments from the Kai Kuutamo Show 2017-2020.</p>

<div className="icont">
<div className="icont2">
<iframe className="video" title="1" src="https://www.youtube.com/embed/mgXEuOOcmTY" allowFullScreen></iframe>
</div>
</div>

<center>
<div className="white-line3"></div>
</center>

<p>Uncut passing juggling acts with Kalle Lehto. Herttakuninkaat 2015.</p>

<div className="icont">
<div className="icont2">
<iframe className="video" title="2" src="https://www.youtube.com/embed/c0TV9iVjoyY" allowFullScreen></iframe>
</div>
</div>

<center>
<div className="white-line3"></div>
</center>

</div>


<div className="videos">

<h3>Cut and Paste</h3>

<p>Merkkarit & Kai Kuutamo group promotion in 2022.</p>

<div className="icont">
<div className="icont2">
<iframe className="video" title="3" src="https://www.youtube.com/embed/N3H_NnM0mlw" allowFullScreen></iframe>
</div>
</div>

<center>
<div className="white-line3"></div>
</center>

<p>A promotion video filmed at Circus Phare, Cambodia. Local style 2012</p>

<div className="icont">
<div className="icont2">
<iframe className="video" title="3" src="https://www.youtube.com/embed/GwU39mFL7Vk" allowFullScreen></iframe>
</div>
</div>

<center>
<div className="white-line3"></div>
</center>

<p>Finnish Poles. Best tricks from the 2000's.</p>

<div className="icont">
<div className="icont2">
<iframe className="video" title="4" src="https://www.youtube.com/embed/UofXrINtKrQ" allowFullScreen></iframe>
</div>
</div>

<center>
<div className="white-line3"></div>
</center>


</div>


<div className="videos">

<h4>Personal Bests</h4>

<p>My record with 10 balls juggling in a show. 2015.</p>

<div className="icont">
<div className="icont2">
<iframe className="video" title="5" src="https://www.youtube.com/embed/kt_masR6uQA" allowFullScreen></iframe>
</div>
</div>

<center>
<div className="white-line3"></div>
</center>

<p>My record with 10 balls juggling in training. 2016.</p>

<div className="icont">
<div className="icont2">
<iframe className="video" title="6" src="https://www.youtube.com/embed/Np22u_L19oc" allowFullScreen></iframe>
</div>
</div>

<center>
<div className="white-line3"></div>
</center>


</div>

<div className="videos">

<h4>Other</h4>

<p>Slideshow from the fifth Multi Bueno Festival held at Barker in 2015. Music by Yakuzi Pato.</p>

<div className="icont">
<div className="icont2">
<iframe className="video" title="7" src="https://www.youtube.com/embed/-P7RSNGgZxc" allowFullScreen></iframe>
</div>
</div>

<center>
<div className="white-line3"></div>
</center>


</div>

<div id="morevideos">

<p>More videos can be found at the Youtube channel <a id="kaiyoutube" href="https://www.youtube.com/user/kaikuutamo/" target="blank">kaikuutamo</a></p>

</div>

<center>
<div className="white-line3"></div>
</center>


</div>,

shows: {

    title1: <h2 className="showstitle">Solo Shows</h2>,
    title2: <h3 className="showstitle">Groups and Troupes</h3>,

    old: 
    <div id="wholinks">
    <h2>Out of repertoire for now</h2>

    <p style={{paddingTop: "5px"}}><a href="http://www.sirkussirkus.com/joulupukki/" rel="noopener noreferrer" target="blank">Joulupukki ja Tempputonttu - Lasten Joululaulusirkusshow</a></p>
    <p style={{paddingTop: "5px"}}><a href="http://www.sirkussirkus.com/kkaa/" rel="noopener noreferrer" target="blank">Kai Kuutamo & Aimo Annos - Circus and Music Spectacle</a></p>
    <p style={{paddingTop: "5px"}}><a href="http://www.sirkussirkus.com/northman/" rel="noopener noreferrer" target="blank">Pohjolan Äijä</a></p>
    <p style={{paddingTop: "5px"}}><a href="http://www.sirkussirkus.com/specialdelivery/" rel="noopener noreferrer" target="blank">Special Delivery</a></p>
    <p><a href="http://www.sirkussirkus.com/tisch/" rel="noopener noreferrer" target="blank">Tisch</a></p>
    <p><a href="https://www.youtube.com/watch?v=GYFoVHWFQDA" rel="noopener noreferrer" target="blank">ALL IN</a></p>
    <p><a href="https://www.youtube.com/watch?v=4PS4xrsHZlg" rel="noopener noreferrer" target="blank">Kai Kuutamo & Miriam Tuominen</a></p>
    <p><a href="https://www.youtube.com/watch?v=egHroF9qGbw" rel="noopener noreferrer" target="blank">Soul on Fire</a></p>
    <p><a href="http://www.sirkussirkus.com/sirkuskunta/esitykset.html" rel="noopener noreferrer" target="blank">Taitureita ja Tunareita</a></p>
    <p><a href="http://www.sirkussirkus.com/sirkuskunta/esitykset.html" rel="noopener noreferrer" target="blank">Punaisen Maneesin Kirjava Sirkus</a></p>
    <p><a href="http://www.sirkussirkus.com/kotiinpaluu/" rel="noopener noreferrer" target="blank">Kotiinpaluu</a></p>

    </div>
},


show:


            {
                show1: 

                <div>

                    <img className="showpic" alt="Show" src="./pictures5/show1.jpg"></img>

                    <h1 className="tabletitle">Kai Kuutamo Show</h1>

        <div className="tableintro">
        <div className="tabletext">
        <p>Updated soon in English.</p>

            <p>Kai Kuutamo Showta on esitetty kodeissa, kaduilla, häissä, vanhainkodeissa, syntymäpäivillä sekä myös suurissa tapahtumissa kansainvälisesti jo vuodesta 2010. Samaisen esityksen vuoden 2012 painoksella voitettiin katuesiintymisen Suomen mestaruus.</p>

            <p>Esitys perustuu verbaaliakrobatian, improvisaation, huumorin ja näyttävien jongleeraustemppujen yhdistelmään. Vuorovaikutus yleisön kanssa on merkittävässä roolissa. Kai Kuutamo Show sopii mihin tahansa tilaan ja tilaisuuteen, ja se voidaan esittää kolmella eri kielellä tai haluttaessa jopa sanattomasti. Esityksen pituuteen voi vaikuttaa, ja sitä voidaan esittää myös kahdessa osassa tai vaikka pieninä palasina juontojen yhteydessä.</p>
        </div>
        </div>

        <br></br>

        <div className="tableintro">
        <div className="tabletext">
        <p>Kesto: 10-45 minuuttia sopimuksen mukaan.</p>
        <p>Kohdeyleisö: Koko perhe. Voidaan myös suunnata tilaisuuden luonteen mukaan aikuisille.</p>
        <p>Kieli: Suomi, Englanti tai Khmer (Kambodza)</p>
        <p>Tila: Soveltuu minne tahansa sisälle tai ulos. Korkeudesta etua.</p>
        <p>(45 minuutin esitykseen minimikorkeus 7 metriä ja lattiatila 8 x 5 metriä.)</p>
        <p>Äänentoisto: Tuodaan mukana sovittaessa.</p>
        <p>Pystytys 30 minuuttia</p>
        <p>Purku 20 minuuttia</p>


        </div>
        </div>

        <br></br>

        <div className="tableintro">
        <div className="tabletext">
            <p>"Although I am blind I could feel your energy. It was a pleasure to experience your show."</p>
            <p>- Akim Color</p>
            <br></br>
            <p>"Sä olet paras esiintyjä!"</p>
            <p>- ala-astelainen</p>
            <br></br>
            <p>"Olipa mielenkiintoinen ja piristävä esitys!"</p>
            <p>- mies vanhainkodissa</p>
            <br></br>
        </div>
        </div>

                    <div className="moremargin"></div>

                    </div>,

                    show1_pics: ["./pictures5/kaikuutamoshow1.jpg", "./pictures5/kaikuutamoshow2.jpg", "./pictures5/kaikuutamoshow3.jpg", "./pictures5/kaikuutamoshow4.jpg", "./pictures5/kaikuutamoshow5.jpg", "./pictures5/kaikuutamoshow6.jpg", "./pictures5/kaikuutamoshow7.jpg", "./pictures5/kaikuutamoshow8.jpg", "./pictures5/kaikuutamoshow9.jpg"],

                    show10: 

                    <div>
    
                        <img className="showpic" alt="Show" src="./pictures5/show10.jpg"></img>
    
                        <h1 className="tabletitle">Kai Kuutamon Matkalaukkusirkus</h1>                    
    
                        <div className="tableintro">
                        <div className="tabletext">
                             
                        <p>Updated soon in English.</p>
    
                            <p>Matkalaukkusirkus on Kai Kuutamon uusi päiväkoti-ikäisille suunnattu kiertävä yhden miehen esitys</p>
    
                            <p>Matkalaukkusirkus on matkalaukkuun mahtuva esitys, joka vie yhden miehen voimin sirkuksen monta jännittävää ulottuvuutta yleisön kuin yleisön luokse. Matkalaukkusirkus on vartavasten suunniteltu sopimaan tilaan kuin tilaan.</p>
    
                            <p>Esityksessä nähdään muun muassa taitavaa korvajongleerausta, vaikeita taikatemppuja, erikoinen pallovipulauta eikä eläinnumeroitakaan ole unohdettu. Näe ratsastava hevonen sekä kuuluisa akrobaattipossu samassa esityksessä! Mutta miten kaikki tämä voi mahtua matkalaukkuun?! Se selviää tietysti Kai Kuutamon Matkalaukkusirkus-esityksessä!</p>
    
                            <p>Sirkustaiteilija Kai Kuutamo juontaa itse koko esityksen, ja vuorovaikutus lapsiyleisön kanssa on tärkeässä roolissa. Esityksessä ei käytetä oikeita eläimiä.</p>
    
                      
                        </div>
                        </div>
    
                        <div className="showbreak"></div>
    
                        <div className="tableintro">
                        <div className="tabletext">
                            <p>Kesto: noin 35 minuuttia.</p>
                            <p>Kohdeyleisö: Päiväkoti-ikäiset ja ala-astelaiset.</p>
                            <p>Tila: Esitys soveltuu millaiseen tilaan vain. Korkeudesta on etua.</p>
                            <p>Kieli: Suomi tai englanti.</p>
                            <p>Äänentoisto: Tuodaan mukana sovittaessa.</p>
                            <p>Pystytys 30 minuuttia</p>
                            <p>Purku 20 minuuttia</p>
    
                            
                        </div>
                        </div>
    <div className="showbreak"></div>

                            <div className="tableintro">
                    <div className="tabletext">
                        <p>”Kuutamo tietää, miten sirkusta tehdään lapsille. Yleisöä kuunnellaan esityksen aikana ja katusirkukselle tyypillinen rento läsnäolo puree myös aikuisiin.”</p>
                        <p>- Turun Sanomat</p>
                        <div className="showbreak2"></div>
                        <p>"Moi Kai, esityksestä jäi todella hyvä mieli. Siitä välittyi yleisöään kunnioittava ammatillinen ote."</p>
                        <p>- Yt. Maarit Kallioniemi, lastentarhanopettaja, Turku </p>
                        <div className="showbreak2"></div>
                        <p>"Tykkäsin todella paljon, ja lapset olivat aivan haltioissaan. Mukavaa kun otit huomioon myös yleisöstä tulleet lukuisat kommentit."</p>
                        <p>- Päiväkodin Johtaja, Aura</p>
                        <div className="showbreak2"></div>
                        <p>"Olit todella läsnä lasten kanssa ja otit heidät hienosti mukaan esitykseen."</p>
                        <p>- Ohjaaja, Silvan päiväkoti, Kemiö</p>
                        
                    </div>
                    </div>
    
                        <div className="showbreak"></div>

                        <div className="tableintro">
                    <div className="tabletext">
                        <p>Voit tutustua Matkalaukkusirkukseen tarkemmin esityksen omilla verkkosivuilla:</p>
                        <p> <a className="showlink" href="http://www.matkalaukkusirkus.com" target="blank">www.matkalaukkusirkus.com</a></p>   
                    </div>
                    </div>
                    


                        <div className="moremargin"></div>

</div>,

show10_pics: ["./pictures5/matkalaukkusirkus1.jpg", "./pictures5/matkalaukkusirkus3.jpg", "./pictures5/matkalaukkusirkus2.jpg",  "./pictures5/matkalaukkusirkus9.jpg","./pictures5/matkalaukkusirkus4.jpg", "./pictures5/matkalaukkusirkus5.jpg", "./pictures5/matkalaukkusirkus6.jpg", "./pictures5/matkalaukkusirkus7.jpg", "./pictures5/matkalaukkusirkus8.jpg"],

                        
show4: 

<div>

    <img className="showpic" alt="Show" src="./pictures5/show4.jpg"></img>

    <h1 className="tabletitle">Prasu</h1>

    <div className="tableintro">
    <div className="tabletext">

    <p>Updated soon in English.</p>

        <p>Prasu on murresana, joka tarkoittaa nuotiota, valkeaa ja tulta. Kai Kuutamon yhden miehen tuliesityksessä Prasussa nähdään tulennielentää, ainutlaatuista soihtujongleerausta sekä palavien köysien vauhdikasta pyöritystä. Tyylikäs, jännittävä ja väkevä esitys pitää otteessaan alusta loppuun asti, eikä varmasti jätä ketään kylmäksi! Esitys sopii kaiken ikäisille, ja se toteutetaan musiikkiin sovitettuna.</p>

        <p>Esityksen soihtujongleerausnumeroa on esitetty jo vuodesta 2004 lähtien mm. Lahden Kaupunginteatterissa, Tampere-talolla, Salpausselän Kisoissa, Turun Linnassa sekä Turun Joulukadun avajaisissa.</p>

        <p>Pidempää kestoa tai lisää näyttävyyttä haluttaessa, esitykseen voidaan yhdistää muita tulitaiteilijoita, pyrotekniikkaa (räjähdykset, kipinäsuihkut ja liekinheittimet) ja/tai ilotulitus.</p>
    </div>
    </div>

    <div className="showbreak"></div>

    <div className="tableintro">
    <div className="tabletext">
        <p>Kesto: 10 minuuttia (suuremmalla ryhmällä 10-25 min)</p>
        <p>Kohdeyleisö: Kaikenikäiset.</p>
        <p>Tila: Yleensä esitetään ulkona. 8x8 metriä tasainen alusta. Madollisimman tuulensuojainen paikka. Sisätiloissa tilan on sovelluttava avotulen käyttöön paloturvallisuussääntöjen mukaisesti.</p>
        <p>Kieli: Sanaton / Musiikki</p>
        <p>Äänentoisto: Tuodaan mukana sovittaessa.</p>
        <p>Pystytys 40 minuuttia</p>
        <p>Purku 30 minuuttia</p>
    </div>
    </div>
   
    
    <div className="moremargin"></div>

</div>,

show4_pics: ["./pictures5/tuliesitys_prasu1.jpg","./pictures5/tuliesitys_prasu8.jpg", "./pictures5/tuliesitys_prasu7.jpg","./pictures5/tuliesitys_prasu5.jpg", "./pictures5/tuliesitys_prasu3.jpg", "./pictures5/tuliesitys_prasu6.jpg"],


                    
                    show2: 

                <div>

                    <img className="showpic" alt="Show" src="./pictures5/show2.jpg"></img>

                    <h1 className="tabletitle">MERKKARIT JA KAI KUUTAMO</h1>

                    <div className="tableintro">
                    <div className="tabletext">

                    <p>Updated soon in English.</p>

                        <p>Lastenmusiikkibändi Merkkarien ja sirkustaiteilija Kai Kuutamon esityksessä yhdistyvät vauhdikas ja omintakeinen lasten rock-musiikki, taitavat sirkustemput sekä hauskat ja osallistavat laululeikit.</p>

                        <p>Esityksessä soivat Merkkareiden omat kappaleet sekä klassikkolastenlaulut. Energinen esitys tempaa yleisön mukaansa, ja tututkin lastenlaulut saavat Merkkarien käsittelyssä aivan uutta virtaa. Kai Kuutamo esittelee kappaleiden säestämänä taitojaan eri jongleerausvälineillä ja johdattelee katsojat mukaan yhteisiin musiikkileikkeihin.</p>

                        <p>MERKKARIT JA KAIKUUTAMO -ryhmän hauskan esityksen parissa viihtyvät helposti myös aikuiset ja nuoriso. Esitys sopii niin suurille festivaaliareenoille ja teatteritiloihin kuin myös pienempiin tilaisuuksiin, päiväkoteihin ja kouluihin.</p>

                    </div>
                    </div>

                    <div className="showbreak"></div>

                    <div className="tableintro">
                    <div className="tabletext">
                    <p>Kesto: 20-40 minuuttia sopimuksen mukaan.</p>
                    <p>Kohdeyleisö: Koko perhe.</p>
                    <p>Tila: Soveltuu monenlaisiin tiloihin sekä äänentoiston että jongleerauksen puolesta.</p>
                    <p>Kieli: Suomi, ruotsi tai kumpaakin.</p>
                    <p>Äänentoisto: Tuodaan mukana sovittaessa.</p>
                    <p>Pystytys: 60 minuuttia</p>
                    <p>Purku: 30 minuuttia</p>
                        
                    </div>
                    </div>

                    <div className="showbreak"></div>

                    <div className="tableintro">
                    <div className="tabletext">
                        <p>Lisätietoa esityksestä ja kokoonpanosta</p> 
                        <p><a className="showlink" href="http://www.sirkussirkus.com/merkkarit/" target="blank">www.merkkarit.com</a></p>
                        
   
                    </div>
                    </div>

                    <div className="moremargin"></div>

                    </div>,

show2_pics: ["./pictures5/merkkaritjakaikuutamo1.jpg", "./pictures5/merkkaritjakaikuutamo2.jpg", "./pictures5/merkkaritjakaikuutamo3.jpg", "./pictures5/merkkaritjakaikuutamo4.jpg", "./pictures5/merkkaritjakaikuutamo8.jpg", "./pictures5/merkkaritjakaikuutamo6.jpg", "./pictures5/merkkaritjakaikuutamo7.jpg", "./pictures5/merkkaritjakaikuutamo9.jpg", "./pictures5/merkkaritjakaikuutamo5.jpg"],


show3: 

                <div>

                    <img className="showpic" alt="Show" src="./pictures5/show3.jpg"></img>

                    <h1 className="tabletitle">Herttakuninkaat</h1>

                    <div className="tableintro">
                    <div className="tabletext">

                    <p>Updated soon in English.</p>

                        <p>"Herttakuninkaiden nimikkoteoksessa Kalle Lehto ja Kai Kuutamo marssittavat yleisön eteen joukon erikoisia hahmoja, jotka kukin esittelevät omaa sirkustaiteen tyylilajiaan aina eläinsirkuksesta nykysirkukseen. Herttakuninkaat lyövät ällikällä koko perheen!"</p>

                        <p>Esityksessä käydään yhdessä yleisön kanssa läpi erilaisia sirkustaiteen tyylilajeja itseironisella ja asiantuntevalla ammattiotteella sekä kerronnan että käytännön kautta. Esitys on ihastuttanut katsojia ympäri Suomea vuodesta 2014 lähtien herskuvalla huumorilla ja taitavilla tempuilla tuoden hymyn mitä vakavimpienkin katsojien huulille. Esitystä on nähty mm. Seikkisrock, Norpas, Skidit sekä SmugsuRock festivaaleilla ja se on kiertänyt yli 60 vanhainkotia Varsinais-Suomessa ja pääkaupunkiseudulla.</p>

                    </div>
                    </div>

                    <div className="showbreak"></div>

                    <div className="tableintro">
                    <div className="tabletext">
                    <p>Kesto: 40 min.</p>
                    <p>Kohdeyleisö: Koko perhe.</p>
                    <p>Tila: Sovellettavissa erilaisiin tiloihin. Korkeudesta etua.</p>
                    <p>Mahdollista esittää myös ulkona.</p>
                    <p>Kieli: Suomi tai englanti.</p>
                    <p>Äänentoisto: Tuodaan mukana sovittaessa.</p>
                    <p>Pystytys: 45 minuuttia</p>
                    <p>Purku: 30 minuuttia</p>   
                    </div>
                    </div>

                    <div className="showbreak"></div>

                    <div className="tableintro">
                    <div className="tabletext">
                        <p>"Tää oli kyllä kivointa mitä meillä on täällä ikinä ollut!"</p>
                        <p>- Vanhainkodin asukas, Nauvo</p>
                        <div className="showbreak2"></div>

                        <p>"Jopa Pena (nimi muutettu), jolla on 10% näöstä jäljellä, nautti esityksestä."</p>
                        <p>- Vanhainkodin hoitaja, Helsinki</p>
                        <div className="showbreak2"></div>

                        <p>"Mukavaa kun oli huumoria mukana. Koko esityksen ajan hymyilytti."</p>
                        <p>- Vanhainkodin asukas, Turku</p>
                        <div className="showbreak2"></div>

                        <p>"Teette hyvää työtä, pojat. Olette taitavia, lahjakkaita ja iloisia."</p>
                        <p>- Vanhainkodin asukas, Hyvinkää</p>
                        <div className="showbreak2"></div>

                        <p>"Juuri oikean mittainen ja oikeanlainen esitys meidän asukeille."</p>
                        <p>- Vanhainkodin hoitaja, Helsinki</p>
                                                
                        <div className="showbreak"></div>

                    </div>
                    </div>


                    <div className="tableintro">
                    <div className="tabletext">
                        <p>Lisätietoa Herttakuninkaista täältä:</p> 
                        <p><a className="showlink" href="http://www.sirkussirkus.com/herttakuninkaat/" target="blank">www.herttakuninkaat.com</a></p>
                        
   
                    </div>
                    </div>

                       


                    <div className="moremargin"></div>

                    <div className="icont_2">
                    <div className="icont2_2">
                    <iframe className="video" title="1" src="https://www.youtube.com/embed/c0TV9iVjoyY" allowFullScreen></iframe>
                    </div>
                    </div>


                    </div>,
show3_pics: ["./pictures5/herttakuninkaat1.jpg", "./pictures5/herttakuninkaat2.jpg", "./pictures5/herttakuninkaat3.jpg", "./pictures5/herttakuninkaat4.jpg", "./pictures5/herttakuninkaat5.jpg", "./pictures5/herttakuninkaat6.jpg", "./pictures5/herttakuninkaat7.jpg", "./pictures5/herttakuninkaat8.jpg", "./pictures5/herttakuninkaat9.jpg"],
                   

show6: 

                <div>

                    <img className="showpic" alt="Show" src="./pictures5/show6.jpg"></img>

                    <h1 className="tabletitle">Rainhard "Pitkä Rane" Reisiheisi & Pitkät Hemmot</h1>
                                        
                    

                    <div className="tableintro">
                    <div className="tabletext">
                         
                    <p>Updated soon in English.</p>

                        <p> Yllä tapahtumasi kanssa uusiin korkeuksiin Pitkä Ranen ja kumppanien avulla! Rainhard Reisiheisi tunnetaan kaverien kesken nimellä Pitkä Rane, ja kavereitahan Ranella riittää minne hän ikinä eksyykään! Pitkällä mitallaan hän tulee helposti huomatuksi, ja iloisen ulospäinsuuntautuneen luonteensa ansiosta hänen kanssa on helppo jutella päivän polttavista aiheista, räätälöidyistä vaatteista tai terveellisestä ruokavaliosta.</p>

                        <p>Pitkä Ranella ei ole varsinaista omaa esitystä, vaan hänet voi tilata kiertelemään tapahtumiin, ja ottamaan kontaktia kävijöihin tai muuten vaan olemaan pitkänä. Lentolehtisten tai esimerkiksi ilmapallojen jakaminen onnistuu myös (tilaaja vastaa materiaaleista).</p>

                        <p>Suurempiin tapahtumiin voit myös tilata 2-5 hengen pitkän seurueen, sillä Rainhard Reisiheisi tuntee pitkien piirit! Usein Pitkä Rane nähdäänkin käyskentelemässä yhdessä hyvän ystävänsä Harri Harha-Askeleen kanssa (kaverikuva alempana).</p>
                    </div>
                    </div>

                    <div className="showbreak"></div>

                    <div className="tableintro">
                    <div className="tabletext">
                        <p>Kesto: Pisin mitta yhdelle kävelylle yhtäjaksoisesti on 1 tunti. Pidemmissä rupeamissa yhtä kävelyä kohti 45 minuuttia lepoa, ellei toisin sovita.</p>
                        <p>Kieli: Suomi, Englanti, Khmer (Kambodza), auttava Ruotsi, haittaava Espanja</p>
                        <p>Tila: Missä tahansa, kunhan maasto on suhteellisen tasaista.</p>
                        <p>Erityistarpeet: Lukittava takalava, jossa 150 cm korkea tukeva alusta pukemista varten.</p>
                    </div>
                    </div>

                    <div className="moremargin"></div>


                    </div>,

                    show6_pics: ["./pictures5/puujalkahahmot1.jpg", "./pictures5/puujalkahahmot2.jpg", "./pictures5/puujalkahahmot3.jpg", "./pictures5/puujalkahahmot4.jpg", "./pictures5/puujalkahahmot5.jpg", "./pictures5/puujalkahahmot6.jpg", "./pictures5/puujalkahahmot7.jpg", "./pictures5/puujalkahahmot8.jpg", "./pictures5/puujalkahahmot9.jpg"],


show8: 

                <div>

                    <img className="showpic" alt="Show" src="./pictures5/show8.jpg"></img>

                    <h1 className="tabletitle">Sirkuskunnan Narrit</h1>
                                        
                    

                    <div className="tableintro">
                    <div className="tabletext">
                         
                    <p>Updated soon in English.</p>

                        <p>Sirkuskunnan Narrit tuovat keskiajan hengen ja hulvattoman meiningin luoksesi leikarimaisella katuesityksellään, joka esittelee yleisölleen aikansa ihmeitä kuten kapuloiden käsittelyä, käsillä tasapainoilua, tynnärin vanteiden pyöritystä, mahtityrväällä isottelua sekä KUOLEMANTEMPPUJA! Koko esitys on aina läpeensä juonnettu ja täynnä hullunkurista, mutta eteenpäin virtaavaa puhtia.</p>

                        <p>Turun Keskiaikaisilta Markkinoilta ponnistaneet Sirkuskunnan Narrit ovat nauttineet vuosien varrella suurenmoista suosiota. He ovat esiintyneet Turun Keskiaikaisilla markkinoilla jo yli 10 vuoden ajan, ja nykyään he vierailevat jo melkein kaikissa Suomen suuremmissa keskiaikatapahtumissa jollain kokoonpanolla. Heitä tapaa myös säännöllisen epäsäännöllisesti Turun Linnan tapahtumissa.</p>

                        <p>Narreja on mahdollista palkata esiintymään 2-5 hengen ryhmässä, jossa jokaisella narrilla on aina muista poikkeava oma erikoistaitonsa. Pisimpään remmissä ovat matkustaneet narrit Urho, Suikki, Yrvänä ja Inkeri. Sirkuskunnan Narrien riveissä ovat vuosien varrella loistaneet myös narrit Elsa, Seikka, Elmeri, Riski-Jooseppi, Iita ja Ongervo.</p>

                        
                    </div>
                    </div>

                    <div className="showbreak"></div>

                    <div className="tableintro">
                    <div className="tabletext">
                        <p>Kesto: 10-30 min. riippuen toiveista ja esiintyjien määrästä.</p>
                        <p>Kieli: Suomi tai Englanti</p>
                        <p>Tila: Missä tahansa. Tasainen alusta ja tuulensuoja eduksi. Yleensä esitetty keskiaikatapahtumissa kadulla tai nurmikolla.</p>
                        
                    </div>
                    </div>

                    <div className="showbreak"></div>

                    <div className="tableintro">
                    <div className="tabletext">
                        <p>"Juuri tällaista huumoria pitää täällä olla!"</p>
                        <p>- Mies Turun Keskiaikaisilla Markkinoilla</p>

                        <div className="showbreak2"></div>

                        <p>"Markkinoiden paras ja hauskin esitys. Ehdottomasti!"</p>
                        <p>- Nainen Turun Keskiaikaisilla Markkinoilla</p>
                        
                    </div>
                    </div>

                    <div className="moremargin"></div>


                    </div>,

                    show8_pics: ["./pictures5/narrit1.jpg", "./pictures5/narrit3.jpg", "./pictures5/narrit4.jpg", "./pictures5/narrit5.jpg", "./pictures5/narrit6.jpg", "./pictures5/narrit8.jpg", "./pictures5/narrit7.jpg", "./pictures5/leikarit.jpg", "./pictures5/urhonarri.jpg"],
   

            },

            articles: 

            <div>
            <div className="tabletitlewrap">
            <h1 className="tabletitle">Finnish Newspaper Articles</h1>
     
            </div>

            <div className="tableintro">
            <div className="tabletext">

            <div className="articlebreak"></div>
            
            <p><span className="articlenewspaper">Aamuset 19.11.2019</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2019-aamuset.jpg" target="_blank"><p>Merkkarit & Kai Kuutamo juhlakeikalla Vimmassa</p></a>

            <div className="articlebreak"></div>
            
            <p><span className="articlenewspaper">Aamuset 23.10.2019</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2019-aamuset-2.jpg" target="_blank"><p>Lonttinen sai uuden seinämaalauksen</p></a>
        
            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 29.9.2019</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2019-turunsanomat-2.jpg" target="_blank"><p>Sirkusarvio: Katusirkuksen rentous kohtaa lastensirkuksen kirjavuuden jonglööri Kai Kuutamon kiertue-esityksessä</p></a>
    
            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Seutusanomat 25.6.2019</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="https://turunseutusanomat.fi/2019/06/harvaluodon-rannalla-keskikesaa-juhlisti-arviolta-yli-600-ihmista/?fbclid=IwAR3UfAptx0jmS5qV1JKButKJ2Wn79-axxwt-YhveYTjpLPvq_r6lkkjnrRc" target="_blank"><p>Harvaluodon rannalla keskikesää juhlisti arviolta yli 600 ihmistä</p></a>
    
            <div className="articlebreak"></div>


            <p><span className="articlenewspaper">Turun Sanomat 2019 kesäkuu</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2019-turunsanomat-3.jpg" target="_blank"><p>Seikkisrockin yhdistämät</p></a>
    
            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 13.4.2019</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2019-turunsanomat-1.jpg" target="_blank"><p>Taiturimaisia temppuja ja vuorovaikutusta</p></a>
    
            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Aamuset 14.2.2018</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2018-aamuset.jpg" target="_blank"><p>Sirkusta Tunteella - Sirkusputiikin kauppias haluaa vähentää kuluttamista</p></a>
    
            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 2017 Lokakuu</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2017-turunsanomat-3.jpg" target="_blank"><p>Kohti Loveen Lankeamista</p></a>
    
            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 2017 Syyskuu</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2017-turunsanomat-2.jpg" target="_blank"><p>Pohjolan Äijä saapuu Metsäkirkkoon</p></a>
    
            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 28.5.2017</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2017-turunsanomat-1.jpg" target="_blank"><p>Rokkimusaa ja jongleerausta</p></a>
    
            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Tienoo 13.4.2017</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2017-turuntienoo.jpg" target="_blank"><p>Merimiesrock sekoitti Ruskotalon nuoren yleisön</p></a>
    
            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Auran Aallot 2015</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.auranaallot.fi/uutiset/paikallinen/festivaali-joka-juhlii-kaikkea-samanaikaisesti-monisyinen-festivaali?fbclid=IwAR3V4Je-MOtEchTinkZbd_mXRbYcl3ZbiMA5aP2obNpmwIKw6K6PZTMtg2c" target="_blank"><p>Festivaali, joka juhlii kaikkea samanaikaisesti on monisyinen festivaali</p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 18.7.2015</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="https://www.ts.fi/uutiset/paikalliset/796198/Sirkustaiteilija+Kai+Kuutamo+rikkoi+jongleerauksen+Suomen+ennatyksen" target="_blank"><p>Sirkustaiteilija Kai Kuutamo rikkoi jongleerauksen Suomen ennätyksen</p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Hoitokoti Päiväkummun Sanomat 1/2015</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2015-paivakummunsanomat.jpg" target="_blank"><p>Sirkushuveja, taikatemppuja ja akrobatiaa hoitokodeissa</p></a>
    
            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Fidea Suomen Draama- ja Teatteriopetuksen liiton jäsenlehti 2014</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2014-fidea.jpg" target="_blank"><p>Circus Kampot - Suomalainen sirkuskoulu Kambodzassa</p></a>
         
            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 6.8.2013</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="https://www.ts.fi/kulttuuri/519198/Rytmikasta+jongleerausta" target="_blank"><p>Rytmikästä Jongleerausta</p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Aamuset 2012 heinäkuu</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2012-aamuset.jpg" target="_blank"><p>Elämä yhtä sirkusta</p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 19.8.2012</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="https://www.ts.fi/kulttuuri/380163/Maailma+on+erilainen+sirkustaiteilijan+silmin" target="_blank"><p>Maailma on erilainen sirkustaiteilijan silmin</p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 2012 elokuu</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2012-turunsanomat.jpg" target="_blank"><p>Kuutamo tarjoaa logistiikkaa ja sirkushuveja</p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">TS-TV 13.8.2012</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://tstv.fi/video/2699" target="_blank"><p>Sirkustaiteilija pahvilaatikoiden keskellä</p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 22.1.2010</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.sirkussirkus.com/artikkelit/2010-turunsanomat.jpg" target="_blank"><p>Ihan Jonglöörinä</p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 19.7.2008</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.ts.fi/viihde/1074295603/Lapsille+raataloity+sirkus+taituroi+Seikkispuistossa" target="_blank"><p>Lapsille räätälöity sirkus taituroi Seikkailupuistossa</p></a>

            <div className="articlebreak"></div>

            <p><span className="articlenewspaper">Turun Sanomat 27.2.2007</span></p>
            <a className="articlelink" rel="noopener noreferrer" href="http://www.ts.fi/viihde/1074183475/Kun+jongloori+palaa+kotiinsa" target="_blank"><p>Kun jonglööri palaa kotiinsa</p></a>

            <div className="articlebreak"></div>


            </div>
            </div>

            </div>,


contact:

<div>

<div className="tableintro">
<div className="tabletext contacttext">
<div className="white-line2"></div>

<p id="contacttitle2">Circus Artist Kai Kuutamo</p>
<p>kai@sirkussirkus.com</p>
<p>p. +358 40 754 6182</p>
<p>Turku</p>

<div className="white-line2"></div>            
</div>
</div>

<div className="tableintro">
<div className="tabletext contacttext">

<p id="contacttitle2">Tips, donations and social media links:</p>
<p><a className="articlelink" href="https://sirkussirkus.com/tip/" rel="noopener noreferrer" target="of">Kai Kuutamo Tip & Donate</a></p>
<p><a className="articlelink" href="https://onlyfans.com/kaikuutamo" rel="noopener noreferrer" target="of">Kai Kuutamo OnlyFans</a></p>
<p><a className="articlelink" href="https://www.instagram.com/kaikuutamo/" rel="noopener noreferrer" target="of">Kai Kuutamo Instagram</a></p>
<p><a className="articlelink" href="https://www.facebook.com/kuutamosirkus/" rel="noopener noreferrer" target="of">Kai Kuutamo & Sirkussirkus Facebook</a></p>
<p><a className="articlelink" href="https://www.tiktok.com/@kaikuutamo" rel="noopener noreferrer" target="of">Kai Kuutamo TikTok</a></p>
<p><a className="articlelink" href="https://www.linkedin.com/in/kaj-mikael-sch%C3%BCtt-28b0ba28/" rel="noopener noreferrer" target="of">Kai Kuutamo LinkedIn</a></p>
<p><a className="articlelink" href="https://g.page/r/CZ6fN9ytpfUYEAI/review" rel="noopener noreferrer" target="of">Sirkussirkus Google Review</a></p>

<div className="white-line2"></div>
</div>
</div>

<div className="tableintro">
<div className="tabletext contacttext">

<p id="contacttitle2">Invoincing and mailing address</p>
<p>Sirkussirkus</p>
<p>Kaj-Mikael Schütt</p>
<p>Y-tunnus: 2003842-8</p>
<p>VAT: FI20038428</p>
<p>Laivateollisuudenkatu 28 A 20</p>
<p>20240 Turku</p>
<p>FINLAND</p>

<div className="white-line2"></div>
</div>
</div>

<div className="tableintro">
<div className="tabletext contacttext">

<p id="contacttitle3">E-invoices</p>
<p>Verkkolaskuosoite 003720038428 (MAVENTA)</p>
<p>Välittäjätunnus 003721291126</p>
<p>Välittäjätunnus pankkiverkossa DABAFIHH</p>

<div className="white-line2"></div>
</div>
</div>


</div>,

form:

<div>

<div className="tabletitlewrap">
        <h1 style={{textAlign: "center"}} className="tabletitle">Thank you!</h1>
        
</div>

<p id="thankyou">Tarjouspyyntösi on lähetetty!</p>

</div>,

promo:

<div>
<div className="tableintro">
<div className="tabletext">
<p id="promopicstext">PROMOTION PHOTOS</p>


<div id="promopics">

<center>

<p>(click to enlarge before downloading)</p>
<div className="white-line"></div>

<p></p>

<div className="promopiccont">
<a href="pictures7/kai-kuutamo-0.jpg" rel="noopener noreferrer" target="blank"><img className="promopic" src="pictures7/promo0.jpg" alt="Kai Kuutamo Promokuva 0"></img></a>
<p>Photographer: Matias Silmunen</p>
<div className="white-line"></div>
</div>

<p></p>


<div className="promopiccont">
<a href="pictures7/kai-kuutamo-1.jpg" rel="noopener noreferrer" target="blank"><img className="promopic" src="pictures7/promo1.jpg" alt="Kai Kuutamo Promokuva 1"></img></a>
<p>Photographer: Matias Silmunen</p>
<div className="white-line"></div>
</div>

<p></p>

<div className="promopiccont">
<a href="pictures7/kai-kuutamo-2.jpg" rel="noopener noreferrer" target="blank"><img className="promopic" src="pictures7/promo2.jpg" alt="Kai Kuutamo Promokuva 2"></img></a>
<p>Photographer: Ville Saarikoski</p>
<div className="white-line"></div>
</div>

<p></p>

<div className="promopiccont">
<a href="pictures7/kai-kuutamo-3.jpg" rel="noopener noreferrer" target="blank"><img className="promopic" src="pictures7/promo3.jpg" alt="Kai Kuutamo Promokuva 3"></img></a>
<p>Photographer: Anna Schütt</p>
<div className="white-line"></div>
</div>

<p></p>

<div className="promopiccont">
<a href="pictures7/kai-kuutamo-4.jpg" rel="noopener noreferrer" target="blank"><img className="promopic" src="pictures7/promo4.jpg" alt="Kai Kuutamo Promokuva 4"></img></a>
<p>Photographer: Matias Silmunen</p>
<div className="white-line"></div>
</div>

<p></p>

<div className="promopiccont">
<a href="pictures7/kai-kuutamo-5.jpg" rel="noopener noreferrer" target="blank"><img className="promopic" src="pictures7/promo5.jpg" alt="Kai Kuutamo Promokuva 5"></img></a>
<p>Photographer: Rauli Katajavuori</p>
<div className="white-line"></div>
</div>

<p></p>


<div className="promopiccont">
<a href="pictures7/kai-kuutamo-6.jpg" rel="noopener noreferrer" target="blank"><img className="promopic" src="pictures7/promo6.jpg" alt="Kai Kuutamo Promokuva 6"></img></a>
<p>Photographer: Flashing Porridge</p>
<div className="white-line"></div>
</div>

<p></p>


<div className="promopiccont">
<a href="pictures7/kai-kuutamo-7.jpg" rel="noopener noreferrer" target="blank"><img className="promopic" src="pictures7/promo7.jpg" alt="Kai Kuutamo Promokuva 7"></img></a>
<p>Photographer: Matias Silmunen</p>
<div className="white-line"></div>
</div>


<p></p>


<div className="promopiccont">
<a href="pictures7/kai-kuutamo-9.jpg" rel="noopener noreferrer" target="blank"><img className="promopic" src="pictures7/promo9.jpg" alt="Kai Kuutamo Promokuva 9"></img></a>
<p>Photographer: Pokko Lemminkäinen</p>
<div className="white-line"></div>
</div>

<p></p>


<div className="promopiccont">
<a href="pictures7/kai-kuutamo-11.jpg" rel="noopener noreferrer" target="blank"><img className="promopic" src="pictures7/promo11.jpg" alt="Kai Kuutamo Promokuva 11"></img></a>
<p>Photographer: Chantrea Yun</p>
<div className="white-line"></div>
</div>

<p></p>


<div className="promopiccont">
<a href="pictures7/kai-kuutamo-12.jpg" rel="noopener noreferrer" target="blank"><img className="promopic" src="pictures7/promo12.jpg" alt="Kai Kuutamo Promokuva 12"></img></a>
<p>Photographer: Vanja Fransson</p>
<div className="white-line"></div>
</div>

<p></p>


<div className="promopiccont">
<a href="pictures7/kai-kuutamo-13.jpg" rel="noopener noreferrer" target="blank"><img className="promopic" src="pictures7/promo13.jpg" alt="Kai Kuutamo Promokuva 13"></img></a>
<p>Photographer: Eero Saarikoski</p>
<div className="white-line"></div>
</div>


<p></p>

</center>
</div>
</div>
</div>
</div>

}


export default english;