import React from 'react';

import {connect} from 'react-redux';

import cloudAnim from './cloudanimation'

import './yritys.css';

import Footer from './footer'

class Yritys extends React.Component {



    componentDidMount () {

        cloudAnim();
    
    }

    render() {


        return (

            <div className="tablewrap">
            <div className="tablewrap2">
            <h1 style={{textAlign: "center"}} className="tabletitle">Juontaja, seremoniamestari tai illan isäntä!</h1>
            <div className="tableintro">
            <div className="tabletext contacttext">

                <p>Seremoniamestarointi, juonnot, tunnelman nostatus ja ylläpito.</p> 
                <p>Sujuvasti, hauskasti ja yllätyksellisesti sopimuksen mukaan sirkustempuilla tai ilman!</p>
                
            <center>
            <div className="white-line4"></div>
            </center>

                <p>- Seremoniamestari</p>
                <p>- Pikkujouluisäntä</p>
                <p>- Avajaisjuontaja</p>
                <p>- Kokousisännöinti</p>
                <p>- Konferenssijuonnot</p>
                <p>- Messujuontaja</p>
                <p>- Festivaali-MC</p>
                <p>- Ostoskeskusistäntä (lavajuonto tai päiväjuonto)</p>
                <p>- Kunnan tapahtumat</p>
                <p>- Julkishallinnon tilaisuudet</p>
                <p>- Piazza-esitykset (risteilijät ja tapahtumat)</p>
                <p>- Lanseeraustilaisuudet</p>
                
            <center>
            <div className="white-line4"></div>
            </center>

                <p>Juontajan rooli ja tehtävä sovitaan yhdessä tilaajan kanssa.</p>
                <p>Ottakaa vapaamuotoisesti yhteyttä.</p>
                <p></p>

                <center>
            <div className="white-line4"></div>
                </center>


                <h1 style={{textAlign: "center"}} className="tabletitle">Jongleeraustyöpajat yrityksille!</h1>
                <p>Tyky-, tyhy- ja tehopäivät, vuosijuhlat, arkihaasteet, viikkovierailut, toimistotaiteilijuus...</p> 
                <p>Hauskaa yhteisöllistä tekemistä koko työryhmälle halutun teeman tai motivaation puitteissa. Työpajat mahdollisia meidän tiloisasmme tai yrityksen omassa sijainnissa. Ei erityisiä tilavaatimuksia.</p>
                <p></p>

            <br></br>
            </div>
            </div>

            </div>
            <Footer />
            </div>

        )


    }



}

const mapStateToProps = (state) => {
    return {
        texts: state.texts
    }
}

export default connect(mapStateToProps)(Yritys);