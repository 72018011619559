import React from 'react';

import './footer.css';


import facebook from '../pictures2/facebook.png'
import instagram from '../pictures2/instagram.png'
import youtube from '../pictures2/youtube.png'
import tiktok from '../pictures2/tiktok.png'
import linkedin from '../pictures2/linkedin.png'
import onlyfans from '../pictures2/onlyfans.png'
import tip from '../pictures2/tip.png'

class Footer extends React.Component {



    render () {


        return (

            <div id="footersocialwrap">
<p></p>
            <div id="footersocialwrap2">
            <a href="https://www.facebook.com/kuutamosirkus/" target="_blank" rel="noopener noreferrer"><img src={facebook} alt="facebook"></img></a>
            <a href="https://www.instagram.com/kaikuutamo/" target="_blank" rel="noopener noreferrer" ><img src={instagram} alt="instagram"></img></a>
            <a href="https://www.youtube.com/user/kaikuutamo/videos" target="_blank" rel="noopener noreferrer" ><img src={youtube} alt="youtube"></img></a>
            <a href="https://www.tiktok.com/@kaikuutamo" target="_blank" rel="noopener noreferrer" ><img src={tiktok} alt="tiktok"></img></a>
            <a href="https://www.linkedin.com/in/kaj-mikael-sch%C3%BCtt-28b0ba28" target="_blank" rel="noopener noreferrer" ><img src={linkedin} alt="linkedin"></img></a>
            <a href="https://tip.sirkussirkus.com" target="_blank" rel="noopener noreferrer" ><img src={tip} alt="tip"></img></a>

          
            

            </div>
<p></p>
            <p>Jonglööri Kai Kuutamo</p>
            <p>kai@sirkussirkus.com</p>
            <p>040 754 6182</p>
 
            </div>
        


        )

    }


}


export default Footer;